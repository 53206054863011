@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;
@use '@carbon/colors' as *;
@use './mixins.scss' as *;
@use './overrides.scss'as *;


.landing-page__illo {
  max-width: 100%;
}

.footer__caption {
  @include type-style('body-01');
  text-align: center;
}

.footer__caption_source {
  @include type-style('productive-heading-01');
  text-align: center;
}

.footer__copyright {
  @include type-style('body-01');
  text-align: center;
  padding-top: $spacing-06;
}


