@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;
@use '@carbon/colors' as *;
@use '@carbon/react/scss/colors';
@use '@carbon/themes';


@mixin sm-breakpoint {
    @media (max-width: 672px) {
      @content; // This allows you to pass in SCSS content when the mixin is included
    }
  }

.login-screen__heading{
    @include type-style('heading-04');
    padding-left: $spacing-05;
    padding-top: $spacing-05;
    padding-bottom: $spacing-06;
  
  }

.login_container {
    padding: $spacing-05;
    background-color: themes.$layer-01;
    display: flex;
    flex-direction: column;
    position:relative;
}

.loginBorder {
    margin-left: $spacing-05;
    margin-right: $spacing-05;
    margin-Bottom: $spacing-07;
    border-bottom: solid 1px colors.$gray-70;
  }

.text-entries{
    margin: $spacing-05;
}

.verifying-copy{
  @include type-style('legal-01');
  margin-top: $spacing-01;
}

.loading-container{
  margin: $spacing-05;
  display: flex;
  align-items: center;
}

.returnHome-button{
    margin-top: $spacing-09*2;
    margin-bottom: $spacing-05;
}