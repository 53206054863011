@use '@carbon/react/scss/spacing' as *;

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; // Ensure this is above all other content
  }
  
  .modal-content {
    background-color: #fff;
    padding: $spacing-05;
    margin: $spacing-05;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  .modal-text-entries{
    margin-top: $spacing-05;
}
  