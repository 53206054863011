@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;
@use '@carbon/colors' as *;
@use '@carbon/themes';


.tabs-group{
    background-color: $layer-01;
    background-color: themes.$layer-01;
    
}

.tab-placement{
    padding-left: $spacing-09;
}

.tabs-group-content {
    padding: $spacing-10 $spacing-06;
  }