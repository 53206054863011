@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/theme' as *;


@mixin landing-page-background() {
  background-color: $layer-01;
  position: relative;
}

@mixin card-bg-color(){
  background-color: $layer-01;
  position: relative;
}



